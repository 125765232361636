var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c("div", { staticClass: "jumbotron m-0 p-0 p-3 jumbotron-fluid" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12 col-lg-12" }, [
                !_vm.registered
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-form",
                          [
                            _c(
                              "b-form-row",
                              [
                                _c("b-col", { attrs: { md: "6" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/logo.svg"),
                                      alt: _vm.$t("app_name"),
                                      height: "150px"
                                    }
                                  })
                                ]),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("h3", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(_vm.$t("register.header")))
                                    ]),
                                    _c("h4", { staticClass: "text-center" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("register.subheader")) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "type-input-group",
                                          label: _vm.$t("register.profile"),
                                          "label-for": "type"
                                        }
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            id: "type",
                                            name: "type",
                                            options: _vm.types,
                                            state: _vm.validateState("type"),
                                            "aria-describedby":
                                              "type-live-feedback"
                                          },
                                          on: { change: _vm.onTypeChanged },
                                          model: {
                                            value: _vm.$v.form.type.$model,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.$v.form.type,
                                                "$model",
                                                $$v
                                              )
                                            },
                                            expression: "$v.form.type.$model"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          {
                                            attrs: { id: "type-live-feedback" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("validation.required")
                                              ) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(_vm.currentForm, {
                          ref: "currentForm",
                          tag: "component",
                          attrs: { "on-submit": _vm.onSubmit }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "alert alert-success",
                        attrs: { role: "alert" }
                      },
                      [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.$t("register.completed_title")))
                        ]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("register.completed_message")))
                        ]),
                        _c(
                          "router-link",
                          { attrs: { to: { name: "login" } } },
                          [
                            _c("b-button", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("buttons.go_to_login")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }