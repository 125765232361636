<template>
  <div>
    <!-- <Carousel /> -->
    <b-overlay :show="loading" rounded="sm">
      <div class="jumbotron m-0 p-0 p-3 jumbotron-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div v-if="!registered">
                <!-- Fake form for type selection -->
                <b-form>
                  <b-form-row>
                    <b-col md="6">
                      <!-- Left slogan -->
                      <img
                        src="@/assets/images/logo.svg"
                        :alt="$t('app_name')"
                        height="150px"
                      />
                    </b-col>
                    <b-col md="6">
                      <!-- Profile type -->
                      <h3 class="text-center">{{ $t("register.header") }}</h3>
                      <h4 class="text-center">
                        {{ $t("register.subheader") }}
                      </h4>
                      <b-form-group
                        id="type-input-group"
                        :label="$t('register.profile')"
                        label-for="type"
                      >
                        <b-form-select
                          id="type"
                          name="type"
                          v-model="$v.form.type.$model"
                          :options="types"
                          @change="onTypeChanged"
                          :state="validateState('type')"
                          aria-describedby="type-live-feedback"
                        ></b-form-select>
                        <b-form-invalid-feedback id="type-live-feedback"
                          >{{ $t("validation.required") }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-form>
                <component
                  ref="currentForm"
                  :is="currentForm"
                  :on-submit="onSubmit"
                ></component>
              </div>
              <div v-else class="alert alert-success" role="alert">
                <h5>{{ $t("register.completed_title") }}</h5>
                <p>{{ $t("register.completed_message") }}</p>
                <router-link :to="{ name: 'login' }"
                  ><b-button class="ml-2">{{
                    $t("buttons.go_to_login")
                  }}</b-button></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
// import Carousel from "@/components/Carousel";
import AffiliateForm from "@/components/forms/registration/AffiliateForm";
import CaretakerForm from "@/components/forms/registration/CaretakerForm";
import CaregiverForm from "@/components/forms/registration/CaregiverForm";
import i18n from "@/i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import RequestServiceForm from "@/components/forms/services/RequestServiceForm";

export default {
  mixins: [validationMixin],
  name: "Register",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.register"),
    meta: [{ name: "description", content: i18n.t("menu.register") }],
  },
  props: {
    profilo: {
      type : String,
      default: null,
    }
  },
  components: {
    RequestServiceForm,
    // Carousel,
    AffiliateForm,
    CaretakerForm,
    CaregiverForm,
  },
  data() {
    return {
      types: [
        { value: null, text: i18n.t("select_placeholder") },
        // { value: "affiliate", text: i18n.t("register.type.affiliate") },
        { value: "caretaker", text: i18n.t("register.type.caretaker") },
        { value: "caregiver", text: i18n.t("register.type.caregiver") },
      ],
      typesComponents: {
        // affiliate: "AffiliateForm",
        caretaker: "CaretakerForm",
        caregiver: "CaregiverForm",
        null: null,
      },
      currentForm: null,
      form: this.getDefaultFormData(),
    };
  },
  computed: {
    loading() {
      return this.$store.getters["account/loading"];
    },
    registered() {
      return this.$store.getters["account/registered"];
    },
  },
  validations: {
    form: {
      type: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("account", ["register"]),
    getDefaultFormData() {
      return {
        type: this.$route.params.profilo,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onTypeChanged() {
      this.currentForm = this.typesComponents[this.form.type];
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      // @todo: Maybe this can be avoided
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        // alert("Errors!");
        return;
      }
      const formData = { type: this.form.type, ...this.$refs.currentForm.form };
      console.log("Register", formData);
      this.register(formData);
    },
  },
  mounted() {
    this.onTypeChanged();
  },
};
</script>

<style scoped>
/* /deep/ .carousel-inner,
/deep/ .carousel-item {
  max-height: 200px;
} */
.alert {
  padding-top: 1.5rem;
}

.alert h5 {
  margin-bottom: 0;
}
</style>
