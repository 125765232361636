<template>
  <b-form @submit.stop.prevent="onChildSubmit">
    <!-- Username -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="username-input-group"
          :label="$t('username')"
          label-for="username"
        >
          <b-form-input
            id="username"
            name="username"
            type="text"
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="username-live-feedback">
            <div v-if="!$v.form.username.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.username.okFromServer">
              {{ formErrors["username"] }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Password + Password Confirmation -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="password1-input-group"
          :label="$t('login.password')"
          label-for="password1"
        >
          <b-form-input
            id="password1"
            name="password1"
            type="password"
            autocomplete="off"
            v-model="$v.form.password1.$model"
            :state="validateState('password1')"
            aria-describedby="password1-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password1-live-feedback">
            <div v-if="!$v.form.password1.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.password1.okFromServer">
              {{ formErrors["password1"] }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="password2-input-group"
          :label="$t('register.password_confirm')"
          label-for="password2"
        >
          <b-form-input
            id="password2"
            name="password2"
            type="password"
            autocomplete="off"
            v-model="$v.form.password2.$model"
            :state="validateState('password2')"
            aria-describedby="password2-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password2-live-feedback">
            <div v-if="!$v.form.password2.sameAsPassword">
              {{ $t("validation.not_same_password") }}
            </div>
            <div v-if="!$v.form.password2.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Name + Surname -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="first_name-input-group"
          :label="$t('register.name')"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            name="first_name"
            type="text"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
            aria-describedby="first_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="first_name-live-feedback">
            <div v-if="!$v.form.first_name.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="last_name-input-group"
          :label="$t('register.surname')"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            name="last_name"
            type="text"
            v-model="$v.form.last_name.$model"
            :state="validateState('last_name')"
            aria-describedby="last_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="last_name-live-feedback">
            <div v-if="!$v.form.last_name.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Email + Phone -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="email-input-group"
          :label="$t('email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            name="email"
            type="text"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            {{ $t("validation.email") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="phone_number-input-group"
          :label="$t('register.phone')"
          label-for="phone_number"
        >
          <b-form-input
            id="phone_number"
            name="phone_number"
            type="text"
            v-model="$v.form.phone_number.$model"
            :state="validateState('phone_number')"
            aria-describedby="phone_number-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="phone_number-live-feedback">
            <div v-if="!$v.form.phone_number.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Birth Date + Photo -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="birth_date-input-group"
          :label="$t('register.birth_date')"
          label-for="birth_date"
        >
          <b-form-input
            id="birth_date"
            type="date"
            v-model="$v.form.birth_date.$model"
            :state="validateState('birth_date')"
            aria-describedby="birth_date-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="birth_date-live-feedback">
            <div v-if="!$v.form.birth_date.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.birth_date.minValue">
              {{ $t("validation.under_age") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="picture-input-group"
          :label="$t('register.photo')"
          label-for="picture"
        >
          <b-form-file
            id="picture"
            required
            :browse-text="$t('browse')"
            v-model="$v.form.picture.$model"
            :state="validateState('picture')"
            :placeholder="$t('file_placeholder')"
            :drop-placeholder="$t('drop_placeholder')"
            aria-describedby="picture-live-feedback"
          ></b-form-file>
          <b-form-invalid-feedback id="picture-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- City Birth + City Live -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="city_birth-input-group"
          :label="$t('register.city_birth')"
          label-for="city_birth"
        >
          <multiselect
            id="city_birth"
            :class="{ 'is-invalid': $v.form.city_birth.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="comune"
            track-by="id"
            :options="cities"
            v-model="$v.form.city_birth.$model"
            :state="validateState('city_birth')"
            aria-describedby="city_birth-live-feedback"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <!-- @see https://stackoverflow.com/questions/68783802/vue-multiselect-template-slot-clear-does-not-work -->
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="city_birth-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="city_live-input-group"
          :label="$t('register.city_live')"
          label-for="city_live"
        >
          <multiselect
            id="city_live"
            :class="{ 'is-invalid': $v.form.city_live.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="comune"
            track-by="id"
            :options="cities"
            v-model="$v.form.city_live.$model"
            :state="validateState('city_live')"
            aria-describedby="city_live-live-feedback"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <!-- @see https://stackoverflow.com/questions/68783802/vue-multiselect-template-slot-clear-does-not-work -->
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="city_live-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Address + Street Number -->
    <b-form-row>
      <b-col lg="9">
        <b-form-group
          id="address-input-group"
          :label="$t('register.address')"
          label-for="address"
        >
          <b-form-input
            id="address"
            name="address"
            type="text"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
            aria-describedby="address-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="address-live-feedback">
            {{ $t("validation.max_length") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group
          id="street_number-input-group"
          :label="$t('register.street_number')"
          label-for="street_number"
        >
          <b-form-input
            id="street_number"
            name="street_number"
            type="text"
            v-model="$v.form.street_number.$model"
            :state="validateState('street_number')"
            aria-describedby="street_number-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="street_number-live-feedback">
            <div v-if="!$v.form.street_number.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Social Account -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="fiscal_code-input-group"
          :label="$t('register.social_account')"
          label-for="fiscal_code"
        >
          <b-form-input
            id="fiscal_code"
            name="fiscal_code"
            type="text"
            v-model="$v.form.fiscal_code.$model"
            :state="validateState('fiscal_code')"
            aria-describedby="fiscal_code-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="fiscal_code-live-feedback">
            <div v-if="!$v.form.fiscal_code.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.fiscal_code.minLength">
              {{ $t("validation.min_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Nationality -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="country-input-group"
          :label="$t('register.nationality')"
          label-for="country"
        >
          <multiselect
            id="country"
            :class="{ 'is-invalid': $v.form.country.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            :object="false"
            :options="countries"
            v-model="$v.form.country.$model"
            :state="validateState('country')"
            aria-describedby="country-live-feedback"
          >
          </multiselect>
          <b-form-invalid-feedback id="country-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Weekly Availability -->
    <b-form-row>
      <b-col lg="12">
        <b-form-group
          id="availability-input-group"
          :label="$t('register.availability')"
          label-for="availability"
        >
          <WeekAvailabilitySelect
            ref="availability"
            id="availability"
            :label="$t('availability_placeholder')"
            v-model="$v.form.availability.$model"
            :v="$v.form.availability"
            :state="validateState('availability')"
            aria-describedby="availability-live-feedback"
          />
          <b-form-invalid-feedback id="availability-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Work Provinces -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="working_provinces-input-group"
          :label="$t('register.work_provinces')"
          label-for="working_provinces"
        >
          <multiselect
            id="working_provinces"
            :class="{ 'is-invalid': $v.form.working_provinces.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="true"
            :placeholder="$t('register.work_provinces_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            :object="false"
            :options="provinces"
            v-model="$v.form.working_provinces.$model"
            :state="validateState('working_provinces')"
            aria-describedby="working_provinces-live-feedback"
          >
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="working_provinces-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="affiliate-input-group"
          :label="$t('register.affiliate')"
          label-for="affiliate"
        >
          <multiselect
            id="affiliate"
            :class="{ 'is-invalid': $v.form.affiliate.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('register.affiliate')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            :options="affiliates"
            v-model="$v.form.affiliate.$model"
            :state="validateState('affiliate')"
            aria-describedby="affiliate-live-feedback"
          >
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="affiliate-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Services -->
    <b-form-row>
      <b-col lg="12">
        <h3 class="text-center">{{ $t("register.select_services") }}</h3>
        <b-form-group label="">
          <b-form-checkbox
            v-for="(service, key) in services"
            v-bind:key="key"
            :value="service.categoria.name"
            v-model="$v.form.servizi.$model"
            :state="validateState('servizi')"
            aria-describedby="servizi-live-feedback"
          >
            {{ service.categoria.name }}
          </b-form-checkbox>
          <b-form-invalid-feedback
            :state="validateState('servizi')"
            id="servizi-live-feedback"
          >
            {{ $t("validation.min_elements") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Referrer -->
    <b-form-row>
      <b-col lg="12">
        <b-form-group
          id="referrer-input-group"
          :label="$t('register.referrer')"
          label-for="referrer"
        >
          <multiselect
            id="referrer"
            :class="{ 'is-invalid': $v.form.referrer.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('register.referrer')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            :options="referrers"
            v-model="$v.form.referrer.$model"
            :state="validateState('referrer')"
            aria-describedby="referrer-live-feedback"
          >
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="referrer-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Bio -->
    <b-form-row>
      <b-col lg="12">
        <h3 class="text-center">{{ $t("register.bio") }}</h3>
        <b-form-group
          id="experience-input-group"
          :label="$t('register.experience')"
          label-for="experience"
        >
          <multiselect
            id="experience"
            :class="{ 'is-invalid': $v.form.experience.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('register.experience')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            :options="experiences"
            v-model="$v.form.experience.$model"
            :state="validateState('experience')"
            aria-describedby="experience-live-feedback"
          >
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="experience-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="">
          <b-form-textarea
            rows="10"
            v-model="$v.form.bio.$model"
            :state="validateState('bio')"
            aria-describedby="bio-live-feedback"
          >
          </b-form-textarea>
          <b-form-invalid-feedback id="bio-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- CV -->
    <b-form-row>
      <b-col lg="12">
        <b-form-group :label="$t('register.cv')">
          <b-form-file
            id="cv"
            required
            :browse-text="$t('browse')"
            v-model="$v.form.cv.$model"
            :state="validateState('cv')"
            :placeholder="$t('file_placeholder')"
            :drop-placeholder="$t('drop_placeholder')"
            aria-describedby="cv-live-feedback"
          ></b-form-file>
          <b-form-invalid-feedback id="cv-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Actions -->
    <b-button type="submit" variant="success"
      >{{ $t("register.register") }}
    </b-button>
    <b-button class="ml-2" @click="resetForm()"
      >{{ $t("register.clear") }}
    </b-button>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  minLength,
  maxLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";
// import SearchableSelect from "@/components/forms/fields/SearchableSelect";
import WeekAvailabilitySelect from "@/components/forms/fields/WeekAvailabilitySelect";
import { fields } from "@/services/endpoints";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { WeekAvailabilitySelect },
  mixins: [validationMixin],
  name: "CaretakerForm",
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      fields: fields,
      experiences: ["Nessuna", "< 12 mesi", "> 12 mesi"],
      referrers: [
        "Ricerca su Web",
        "Segnalazione collega/amico",
        "Pubblicità su Internet",
        "Altro",
      ],
      affiliates: [
        "Albino",
        "Ascoli",
        "Bari",
        "Biella",
        "Borgosesia",
        "Brugherio",
        "Castiglione del Lago",
        "Catania",
        "Chivasso",
        "Cologno Monzese",
        "Cormano",
        "Cremona",
        "Dalmine",
        "Feltre",
        "Garbagnate",
        "Genova Nervi",
        "Guidizzolo",
        "Inveruno",
        "Ivrea",
        "Legnano",
        "Lissone",
        "Macherio",
        "Mantova",
        "Messina",
        "Milano Bicocca",
        "Milano Porta Venezia",
        "Milano Trivulzio",
        "Milano Viale Corsica",
        "My Care Milano",
        "MyCare Sede Centrale",
        "Novara",
        "Ortona",
        "Rieti",
        "Roma Appio",
        "Spoleto",
        "Torino Crocetta",
        "Torino Lingotto",
        "Torino Sud",
        "Val di Cecina",
        "Valli di Lanzo",
        "Varese",
        "Verona",
      ],
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/cities",
      provinces: "cities/provinces",
      countries: "cities/countries",
      services: "services/services",
      formErrors: "account/getFormErrors",
    }),
  },
  watch: {
    form: {
      // eslint-disable-next-line no-unused-vars
      handler(_newValue, _oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        // @todo: Find a better way to set new value in child
        // this.$refs.availability.value = this.form.availability;
      },
      deep: true,
    },
  },
  validations: {
    form: {
      username: {
        required,
        okFromServer() {
          return !this.formErrors || !this.formErrors["username"];
        },
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      password1: {
        required,
        okFromServer() {
          return !this.formErrors || !this.formErrors["password1"];
        },
      },
      password2: {
        required,
        sameAsPassword: sameAs("password1"),
        okFromServer() {
          return !this.formErrors || !this.formErrors["password2"];
        },
      },
      email: {
        email,
      },
      phone_number: {
        required,
        // @todo: Phone validation
      },
      picture: {
        required,
        // @todo: Photo validation
      },
      birth_date: {
        required,
        minValue(val) {
          return this.$isOver18(new Date(val));
        },
      },
      city_birth: {
        required,
      },
      city_live: {
        required,
      },
      address: {
        maxLength: maxLength(255),
      },
      street_number: {
        required,
      },
      fiscal_code: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      country: {
        required,
      },
      availability: {
        minLength: minLength(1),
        /* $each: {
          required
        }, */
        // @todo: Availability validation
      },
      working_provinces: {
        required,
      },
      affiliate: {
        required,
      },
      servizi: {
        required,
        minLength: minLength(1),
      },
      referrer: {},
      experience: {
        required,
      },
      bio: {},
      cv: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
      getProvinces: "cities/getProvinces",
      getCountries: "cities/getCountries",
      getServices: "services/getAll",
      clearRegistration: "account/clearRegistration",
    }),
    getDefaultFormData() {
      return {
        username: null,
        first_name: null,
        last_name: null,
        password1: null,
        password2: null,
        email: null,
        phone_number: null,
        picture: null,
        birth_date: null,
        city_birth: null,
        city_live: null,
        address: null,
        street_number: null,
        fiscal_code: null,
        country: null,
        availability: [],
        working_provinces: [],
        affiliate: null,
        servizi: [],
        referrer: null,
        experience: null,
        bio: null,
        cv: null,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChildSubmit() {
      // Clear server-side form errors
      this.clearRegistration();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // API mapping
      this.form.city_id_birth = this.form.city_birth.id;
      this.form.city_id_live = this.form.city_live.id;
      this.form["Affiliato_di_Appartenenza"] = this.form.affiliate;
      this.form["Come_ci_hai_conosciuto"] = this.form.referrer;
      this.form["Competenze"] = this.form.experience;
      this.form["Note_sulle_competenze"] = this.form.bio;
      this.onSubmit();
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  mounted() {
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      this.getCities();
    }
    if (!this.provinces || !this.provinces.length) {
      // Load from API
      console.log("Loading provinces from API");
      this.getProvinces();
    }
    if (!this.countries || !this.countries.length) {
      // Load from API
      console.log("Loading countries from API");
      this.getCountries();
    }
    if (!this.services || !this.services.length) {
      // Load from API
      console.log("Loading services from API");
      this.getServices();
    }
  },
};
</script>
