var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onChildSubmit($event)
        }
      }
    },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "username-input-group",
                    label: _vm.$t("username"),
                    "label-for": "username"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "username",
                      name: "username",
                      type: "text",
                      state: _vm.validateState("username"),
                      "aria-describedby": "username-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.username.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.username, "$model", $$v)
                      },
                      expression: "$v.form.username.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "username-live-feedback" } },
                    [
                      !_vm.$v.form.username.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.username.okFromServer
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.formErrors["username"]) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "ragione_sociale-input-group",
                    label: _vm.$t("register.legal_name"),
                    "label-for": "ragione_sociale"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "ragione_sociale",
                      name: "ragione_sociale",
                      type: "text",
                      state: _vm.validateState("ragione_sociale"),
                      "aria-describedby": "ragione_sociale-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.ragione_sociale.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.ragione_sociale, "$model", $$v)
                      },
                      expression: "$v.form.ragione_sociale.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "ragione_sociale-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "email-input-group",
                    label: _vm.$t("email"),
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      name: "email",
                      type: "text",
                      state: _vm.validateState("email"),
                      "aria-describedby": "email-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.email, "$model", $$v)
                      },
                      expression: "$v.form.email.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "email-live-feedback" } },
                    [
                      !_vm.$v.form.email.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.email.email
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.email")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.email.okFromServer
                        ? _c("div", [
                            _vm._v(" " + _vm._s(_vm.formErrors["email"]) + " ")
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "first_name-input-group",
                    label: _vm.$t("register.name"),
                    "label-for": "first_name"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "first_name",
                      name: "first_name",
                      type: "text",
                      state: _vm.validateState("first_name"),
                      "aria-describedby": "first_name-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.first_name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.first_name, "$model", $$v)
                      },
                      expression: "$v.form.first_name.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "first_name-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "last_name-input-group",
                    label: _vm.$t("register.surname"),
                    "label-for": "last_name"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "last_name",
                      name: "last_name",
                      type: "text",
                      state: _vm.validateState("last_name"),
                      "aria-describedby": "last_name-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.last_name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.last_name, "$model", $$v)
                      },
                      expression: "$v.form.last_name.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "last_name-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "p_iva-input-group",
                    label: _vm.$t("register.vat_account"),
                    "label-for": "p_iva"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "p_iva",
                      name: "p_iva",
                      type: "text",
                      state: _vm.validateState("p_iva"),
                      "aria-describedby": "p_iva-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.p_iva.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.p_iva, "$model", $$v)
                      },
                      expression: "$v.form.p_iva.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "p_iva-live-feedback" } },
                    [
                      !_vm.$v.form.p_iva.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.p_iva.minLength
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.min_length")) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "phone_number-input-group",
                    label: _vm.$t("register.phone"),
                    "label-for": "phone_number"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "phone_number",
                      name: "phone_number",
                      type: "text",
                      state: _vm.validateState("phone_number"),
                      "aria-describedby": "phone_number-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.phone_number.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.phone_number, "$model", $$v)
                      },
                      expression: "$v.form.phone_number.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "phone_number-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "agency_id-input-group",
                    label: _vm.$t("register.agency"),
                    "label-for": "agency_id"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.agency_id.$error },
                      attrs: {
                        id: "agency_id",
                        "close-on-select": true,
                        "clear-on-select": false,
                        "aria-autocomplete": false,
                        multiple: false,
                        placeholder: _vm.$t("select_placeholder"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        label: "name",
                        "track-by": "id",
                        options: _vm.agencies,
                        state: _vm.validateState("agency_id"),
                        "aria-describedby": "agency_id-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.agency_id.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.agency_id, "$model", $$v)
                        },
                        expression: "$v.form.agency_id.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "agency_id-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "password1-input-group",
                    label: _vm.$t("login.password"),
                    "label-for": "password1"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password1",
                      name: "password1",
                      type: "password",
                      state: _vm.validateState("password1"),
                      "aria-describedby": "password1-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.password1.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.password1, "$model", $$v)
                      },
                      expression: "$v.form.password1.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "password1-live-feedback" } },
                    [
                      !_vm.$v.form.password1.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.password1.okFromServer
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.formErrors["password1"]) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "password2-input-group",
                    label: _vm.$t("register.password_confirm"),
                    "label-for": "password2"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password2",
                      name: "password2",
                      type: "password",
                      state: _vm.validateState("password2"),
                      "aria-describedby": "password2-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.password2.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.password2, "$model", $$v)
                      },
                      expression: "$v.form.password2.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "password2-live-feedback" } },
                    [
                      !_vm.$v.form.password2.sameAsPassword
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.not_same_password")) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.password2.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "city_id-input-group",
                    label: _vm.$t("register.city"),
                    "label-for": "city_id"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.city_id.$error },
                      attrs: {
                        id: "city_id",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("select_placeholder"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        label: "comune",
                        "track-by": "id",
                        options: _vm.cities,
                        state: _vm.validateState("city_id"),
                        "aria-describedby": "city_id-live-feedback"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        },
                        {
                          key: "singleLabel",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.$v.form.city_id.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.city_id, "$model", $$v)
                        },
                        expression: "$v.form.city_id.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "city_id-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "address-input-group",
                    label: _vm.$t("register.address"),
                    "label-for": "address"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "address",
                      name: "address",
                      type: "text",
                      state: _vm.validateState("address"),
                      "aria-describedby": "address-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.address.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.address, "$model", $$v)
                      },
                      expression: "$v.form.address.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "address-live-feedback" } },
                    [
                      !_vm.$v.form.address.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.address.maxLength
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.max_length")) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticStyle: {
                        position: "relative",
                        height: "300px",
                        "overflow-y": "scroll"
                      },
                      attrs: { id: "privacy-text" }
                    },
                    [
                      _c("LipsumParagraph"),
                      _c("LipsumParagraph"),
                      _c("LipsumParagraph")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-2 mb-3",
                  attrs: {
                    id: "privacy-input-group",
                    label: "",
                    "label-for": "privacy"
                  }
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "privacy",
                        name: "privacy",
                        value: true,
                        "unchecked-value": false,
                        state: _vm.validateState("privacy"),
                        "aria-describedby": "privacy-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.privacy.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.privacy, "$model", $$v)
                        },
                        expression: "$v.form.privacy.$model"
                      }
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("register.privacy_accept")))
                      ])
                    ]
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        state: _vm.validateState("privacy"),
                        id: "privacy-live-feedback"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-button", { attrs: { type: "submit", variant: "success" } }, [
        _vm._v(_vm._s(_vm.$t("register.register")) + " ")
      ]),
      _c(
        "b-button",
        {
          staticClass: "ml-2",
          on: {
            click: function($event) {
              return _vm.resetForm()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("register.clear")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }