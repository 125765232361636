<template>
  <b-form @submit.stop.prevent="onChildSubmit">
    <!-- Username -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="username-input-group"
          :label="$t('username')"
          label-for="username"
        >
          <b-form-input
            id="username"
            name="username"
            type="text"
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="username-live-feedback">
            <div v-if="!$v.form.username.required">
              {{ $t("validation.required") }}
            </div>
            <!-- <div v-if="!$v.form.username.okFromServer"> -->
            <!-- <div>
              {{ formErrors["username"] }}
            </div> -->
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Password + Password Confirmation -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="password1-input-group"
          :label="$t('login.password')"
          label-for="password1"
        >
          <b-form-input
            id="password1"
            name="password1"
            type="password"
            v-model="$v.form.password1.$model"
            :state="validateState('password1')"
            aria-describedby="password1-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password1-live-feedback">
            <div v-if="!$v.form.password1.required">
              {{ $t("validation.required") }}
            </div>
            <!-- <div v-if="!$v.form.password1.okFromServer">
              {{ formErrors["password1"] }}
            </div> -->
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="password2-input-group"
          :label="$t('register.password_confirm')"
          label-for="password2"
        >
          <b-form-input
            id="password2"
            name="password2"
            type="password"
            v-model="$v.form.password2.$model"
            :state="validateState('password2')"
            aria-describedby="password2-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password2-live-feedback">
            <div v-if="!$v.form.password2.sameAsPassword">
              {{ $t("validation.not_same_password") }}
            </div>
            <div v-if="!$v.form.password2.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Name + Surname -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="name-input-group"
          :label="$t('register.name')"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            name="first_name"
            type="text"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
            aria-describedby="first_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="first_name-live-feedback">
            <div v-if="!$v.form.first_name.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="last_name-input-group"
          :label="$t('register.surname')"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            name="last_name"
            type="text"
            v-model="$v.form.last_name.$model"
            :state="validateState('last_name')"
            aria-describedby="last_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="last_name-live-feedback">
            <div v-if="!$v.form.last_name.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Email + Phone -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="email-input-group"
          :label="$t('email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            name="email"
            type="text"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            <div v-if="!$v.form.email.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.email.email">
              {{ $t("validation.email") }}
            </div>
            <div v-if="!$v.form.email.okFromServer">
              {{ formErrors["email"] }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="phone_number-input-group"
          :label="$t('register.phone')"
          label-for="phone_number"
        >
          <b-form-input
            id="phone_number"
            name="phone_number"
            type="text"
            v-model="$v.form.phone_number.$model"
            :state="validateState('phone_number')"
            aria-describedby="phone_number-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="phone_number-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- City + Address -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="city-input-group"
          :label="$t('register.city')"
          label-for="city"
        >
          <multiselect
            id="city"
            :class="{ 'is-invalid': $v.form.city.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="comune"
            track-by="id"
            :options="cities"
            v-model="$v.form.city.$model"
            :state="validateState('city')"
            aria-describedby="city-live-feedback"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <!-- @see https://stackoverflow.com/questions/68783802/vue-multiselect-template-slot-clear-does-not-work -->
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="city-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="address-input-group"
          :label="$t('register.address')"
          label-for="address"
        >
          <b-form-input
            id="address"
            name="address"
            type="text"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
            aria-describedby="address-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="address-live-feedback">
            <div v-if="!$v.form.address.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.address.maxLength">
              {{ $t("validation.max_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Street Number + Social Account -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="street_number-input-group"
          :label="$t('register.street_number')"
          label-for="street_number"
        >
          <b-form-input
            id="street_number"
            name="street_number"
            type="text"
            v-model="$v.form.street_number.$model"
            :state="validateState('street_number')"
            aria-describedby="street_number-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="street_number-live-feedback">
            <div v-if="!$v.form.street_number.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="fiscal_code-input-group"
          :label="$t('register.social_account')"
          label-for="fiscal_code"
        >
          <b-form-input
            id="fiscal_code"
            name="fiscal_code"
            type="text"
            v-model="$v.form.fiscal_code.$model"
            :state="validateState('fiscal_code')"
            aria-describedby="fiscal_code-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="fiscal_code-live-feedback">
            <div v-if="!$v.form.fiscal_code.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.fiscal_code.minLength">
              {{ $t("validation.min_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Notes -->
    <b-form-row>
      <b-col lg="12">
        <h3 class="text-center">{{ $t("register.notes") }}</h3>
        <b-form-group label="">
          <b-form-textarea
            rows="10"
            required
            :placeholder="$t('register.notes_placeholder')"
            v-model="$v.form.note.$model"
            :state="validateState('note')"
            aria-describedby="note-live-feedback"
          >
          </b-form-textarea>
          <b-form-invalid-feedback id="note-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Privacy Text + Checkbox -->
    <b-form-row class="mt-3">
      <b-col lg="12">
        <b-card no-body>
          <b-card-body
            id="privacy-text"
            style="position: relative; height: 300px; overflow-y: scroll;"
          >
            <LipsumParagraph />
            <LipsumParagraph />
            <LipsumParagraph />
          </b-card-body>
        </b-card>
        <b-form-group
          id="privacy-input-group"
          label=""
          label-for="privacy"
          class="mt-2 mb-3"
        >
          <b-form-checkbox
            id="privacy"
            name="privacy"
            v-model="$v.form.privacy.$model"
            :value="true"
            :unchecked-value="false"
            :state="validateState('privacy')"
            aria-describedby="privacy-live-feedback"
          >
            <span class="font-weight-bold">{{
              $t("register.privacy_accept")
            }}</span>
          </b-form-checkbox>
          <b-form-invalid-feedback
            :state="validateState('privacy')"
            id="privacy-live-feedback"
          >
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Actions -->
    <b-button type="submit" variant="success"
      >{{ $t("register.register") }}
    </b-button>
    <b-button class="ml-2" @click="resetForm()"
      >{{ $t("register.clear") }}
    </b-button>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";
import LipsumParagraph from "@/components/test/LipsumParagraph";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { LipsumParagraph },
  mixins: [validationMixin],
  name: "CaregiverForm",
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/cities",
      formErrors: "account/getFormErrors",
    }),
  },
  validations: {
    form: {
      username: {
        required,
        okFromServer() {
          return !this.formErrors || !this.formErrors["username"];
        },
      },
      password1: {
        required,
        okFromServer() {
          return !this.formErrors || !this.formErrors["password1"];
        },
      },
      password2: {
        required,
        sameAsPassword: sameAs("password1"),
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
        okFromServer() {
          return !this.formErrors || !this.formErrors["email"];
        },
      },
      phone_number: {
        required,
        // @todo: Phone validation
      },
      city: {
        required,
      },
      address: {
        required,
        maxLength: maxLength(255),
      },
      street_number: {
        required,
      },
      fiscal_code: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      note: {
        required,
      },
      privacy: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
      clearRegistration: "account/clearRegistration",
    }),
    getDefaultFormData() {
      return {
        username: null,
        password1: null,
        password2: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        city: null,
        address: null,
        street_number: null,
        fiscal_code: null,
        note: null,
        privacy: false,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChildSubmit() {
      // Clear server-side form errors
      this.clearRegistration();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // For APIs
      this.form.city_id = this.form.city.id;
      /* const city = this.cities.find((item) => item.id === this.form.city_id);
      if (city) {
        this.form.city = city.comune;
      } */
      this.onSubmit();
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  mounted() {
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      this.getCities();
    }
  },
};
</script>
