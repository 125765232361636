<template>
  <b-form @submit.stop.prevent="onChildSubmit">
    <!-- Username -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="username-input-group"
          :label="$t('username')"
          label-for="username"
        >
          <b-form-input
            id="username"
            name="username"
            type="text"
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="username-live-feedback">
            <div v-if="!$v.form.username.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.username.okFromServer">
              {{ formErrors["username"] }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Legal Name + Email -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="ragione_sociale-input-group"
          :label="$t('register.legal_name')"
          label-for="ragione_sociale"
        >
          <b-form-input
            id="ragione_sociale"
            name="ragione_sociale"
            type="text"
            v-model="$v.form.ragione_sociale.$model"
            :state="validateState('ragione_sociale')"
            aria-describedby="ragione_sociale-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="ragione_sociale-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="email-input-group"
          :label="$t('email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            name="email"
            type="text"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            <div v-if="!$v.form.email.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.email.email">
              {{ $t("validation.email") }}
            </div>
            <div v-if="!$v.form.email.okFromServer">
              {{ formErrors["email"] }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Name + Surname -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="first_name-input-group"
          :label="$t('register.name')"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            name="first_name"
            type="text"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
            aria-describedby="first_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="first_name-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="last_name-input-group"
          :label="$t('register.surname')"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            name="last_name"
            type="text"
            v-model="$v.form.last_name.$model"
            :state="validateState('last_name')"
            aria-describedby="last_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="last_name-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- VAT Account + Phone -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="p_iva-input-group"
          :label="$t('register.vat_account')"
          label-for="p_iva"
        >
          <b-form-input
            id="p_iva"
            name="p_iva"
            type="text"
            v-model="$v.form.p_iva.$model"
            :state="validateState('p_iva')"
            aria-describedby="p_iva-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="p_iva-live-feedback">
            <div v-if="!$v.form.p_iva.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.p_iva.minLength">
              {{ $t("validation.min_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="phone_number-input-group"
          :label="$t('register.phone')"
          label-for="phone_number"
        >
          <b-form-input
            id="phone_number"
            name="phone_number"
            type="text"
            v-model="$v.form.phone_number.$model"
            :state="validateState('phone_number')"
            aria-describedby="phone_number-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="phone_number-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Agency -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="agency_id-input-group"
          :label="$t('register.agency')"
          label-for="agency_id"
        >
          <multiselect
            id="agency_id"
            :class="{ 'is-invalid': $v.form.agency_id.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :aria-autocomplete="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="name"
            track-by="id"
            :options="agencies"
            v-model="$v.form.agency_id.$model"
            :state="validateState('agency_id')"
            aria-describedby="agency_id-live-feedback"
          >
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="agency_id-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Password + Password Confirmation -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="password1-input-group"
          :label="$t('login.password')"
          label-for="password1"
        >
          <b-form-input
            id="password1"
            name="password1"
            type="password"
            v-model="$v.form.password1.$model"
            :state="validateState('password1')"
            aria-describedby="password1-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password1-live-feedback">
            <div v-if="!$v.form.password1.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.password1.okFromServer">
              {{ formErrors["password1"] }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="password2-input-group"
          :label="$t('register.password_confirm')"
          label-for="password2"
        >
          <b-form-input
            id="password2"
            name="password2"
            type="password"
            v-model="$v.form.password2.$model"
            :state="validateState('password2')"
            aria-describedby="password2-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password2-live-feedback">
            <div v-if="!$v.form.password2.sameAsPassword">
              {{ $t("validation.not_same_password") }}
            </div>
            <div v-if="!$v.form.password2.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- City + Address -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="city_id-input-group"
          :label="$t('register.city')"
          label-for="city_id"
        >
          <multiselect
            id="city_id"
            :class="{ 'is-invalid': $v.form.city_id.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="comune"
            track-by="id"
            :options="cities"
            v-model="$v.form.city_id.$model"
            :state="validateState('city_id')"
            aria-describedby="city_id-live-feedback"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="city_id-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="address-input-group"
          :label="$t('register.address')"
          label-for="address"
        >
          <b-form-input
            id="address"
            name="address"
            type="text"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
            aria-describedby="address-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="address-live-feedback">
            <div v-if="!$v.form.address.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.address.maxLength">
              {{ $t("validation.max_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Privacy Text + Checkbox -->
    <b-form-row class="mt-3">
      <b-col lg="12">
        <b-card no-body>
          <b-card-body
            id="privacy-text"
            style="position: relative; height: 300px; overflow-y: scroll;"
          >
            <LipsumParagraph />
            <LipsumParagraph />
            <LipsumParagraph />
          </b-card-body>
        </b-card>
        <b-form-group
          id="privacy-input-group"
          label=""
          label-for="privacy"
          class="mt-2 mb-3"
        >
          <b-form-checkbox
            id="privacy"
            name="privacy"
            v-model="$v.form.privacy.$model"
            :value="true"
            :unchecked-value="false"
            :state="validateState('privacy')"
            aria-describedby="privacy-live-feedback"
          >
            <span class="font-weight-bold">{{
              $t("register.privacy_accept")
            }}</span>
          </b-form-checkbox>
          <b-form-invalid-feedback
            :state="validateState('privacy')"
            id="privacy-live-feedback"
          >
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Actions -->
    <b-button type="submit" variant="success"
      >{{ $t("register.register") }}
    </b-button>
    <b-button class="ml-2" @click="resetForm()"
      >{{ $t("register.clear") }}
    </b-button>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  sameAs,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import LipsumParagraph from "@/components/test/LipsumParagraph";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { LipsumParagraph },
  mixins: [validationMixin],
  name: "AffiliateForm",
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      agencies: [],
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/cities",
      formErrors: "account/getFormErrors",
    }),
  },
  validations: {
    form: {
      username: {
        required,
        okFromServer() {
          return !this.formErrors || !this.formErrors["username"];
        },
      },
      ragione_sociale: {
        required,
      },
      email: {
        required,
        email,
        okFromServer() {
          return !this.formErrors || !this.formErrors["email"];
        },
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      p_iva: {
        required,
        minLength: minLength(11),
      },
      phone_number: {
        required,
      },
      agency_id: {
        // required,
      },
      password1: {
        required,
        okFromServer() {
          return !this.formErrors || !this.formErrors["password1"];
        },
      },
      password2: {
        required,
        sameAsPassword: sameAs("password1"),
      },
      city_id: {
        required,
      },
      address: {
        required,
        maxLength: maxLength(255),
      },
      privacy: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
      clearRegistration: "account/clearRegistration",
    }),
    getDefaultFormData() {
      return {
        username: null,
        ragione_sociale: null,
        email: null,
        first_name: null,
        last_name: null,
        p_iva: null,
        phone_number: null,
        agency_id: null,
        password1: null,
        password2: null,
        city_id: null,
        address: null,
        privacy: false,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChildSubmit() {
      // Clear server-side form errors
      this.clearRegistration();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const city = this.cities.find((item) => item.id === this.form.city_id);
      if (city) {
        this.form.city = city.comune;
      }
      this.onSubmit();
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  mounted() {
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      this.getCities();
    }
  },
};
</script>
