var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", [
    _vm._v(
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pharetra vulputate quam quis hendrerit. Curabitur auctor justo sed dolor lobortis, ut finibus diam tempor. Nullam lobortis venenatis quam vitae blandit. Curabitur ac erat et purus facilisis laoreet. Sed sagittis, elit nec bibendum ullamcorper, purus lectus molestie ligula, in consequat dolor lorem sollicitudin nunc. Nam pulvinar mi nec sollicitudin aliquet. Nam eget posuere lectus. Suspendisse fermentum euismod luctus. Nulla ligula sem, convallis vel ullamcorper id, bibendum ac leo. Aliquam sed cursus nunc. "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }